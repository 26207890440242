.dashboard {
    position: relative;
    width: 100%;
}

.dashboard .anticon {
    top: 4px;
    right: 4px;
}

.dashboard .widget {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 8px;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 1px #ffffff inset, 0 1px 3px rgba(34, 25, 25, 0.4);
}

.dashboard .widget--header {
    display: flex;
    color: #333;
    cursor: pointer;
}

.dashboard .widget--content {
    flex: 1 1 0%;
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: 8px;
}

.dashboard .widget--header-info {
    flex: 1 auto;
    text-align: center;
}

.dashboard .widget--options {
    margin: 0 10px;
    font-size: 20px;
}

.dashboard .widget--close-btn {
    font-size: 20px;
    font-weight: 400;
}

.dashboard .widget--title {
    text-align: center;
    font-size: 20px;
    flex: 1 0 0%;
}

.swal2-content {
    text-align: left !important;
}

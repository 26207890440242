.overview-header {
    text-align: center;
    font-size: 28px;
    padding: 1em 0;
}

.overview-report-link {
    text-align: center;
    padding-bottom: 1em;
}

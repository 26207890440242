.custom-filter-dropdown {
    padding: 8px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
}

.card-body {
    word-break: break-all;
    white-space: pre-wrap;
}
